// Pre-declare all possible observer imports

const observerImports = {
  ThemeSwitch: () => import('./ThemeSwitch/index.js'),
  Article: () => import('./Article/index.js'),
  // Add more observers here
};

class ObserverManager {
  constructor() {
    this.activeObservers = new Map();
    this.loadedModules = new Map();
  }

  async init() {
    const elements = document.querySelectorAll('[data-observer]');
    for (const element of elements) {
      await this.initializeObserver(element);
    }
    console.log('Initializing ObserverManager');
    //console.log('Loaded observers:', Array.from(this.loadedModules.keys()));
  }

  async initializeObserver(element) {
    const observerNames = element.dataset.observer.split(',');
    for (const name of observerNames) {
      await this.loadAndInitializeObserver(name.trim(), element);
    }
  }

  async loadAndInitializeObserver(name, element) {
    if (!observerImports[name]) {
      //console.warn(`Observer "${name}" not found in pre-declared imports. Skipping.`);
      return; // Skip this observer and continue with others
    }

    try {
      let module;
      if (!this.loadedModules.has(name)) {
        module = await observerImports[name]();
        this.loadedModules.set(name, module);
      } else {
        module = this.loadedModules.get(name);
      }

      const Observer = module.default;
      const observer = new Observer(element);
      
      if (!this.activeObservers.has(element)) {
        this.activeObservers.set(element, []);
      }
      this.activeObservers.get(element).push(observer);
      
      await observer.init();
    } catch (error) {
      //console.error(`Failed to initialize observer: ${name}`, error);
      //console.error('Error details:', error.message);
      if (error.stack) console.error('Stack trace:', error.stack);
    }
  }

  destroyAll() {
    this.activeObservers.forEach((observers, element) => {
      observers.forEach(observer => {
        if (typeof observer.destroy === 'function') {
          observer.destroy();
        }
      });
    });
    this.activeObservers.clear();
    this.loadedModules.clear();
  }
}

export default ObserverManager;